"use client";

import Icon from "components/Icon";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import { useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { cn } from "utils/functions";
import { analytics } from "lib/segment";

export default function DownloadForm({
  json,
  assets,
}: {
  json: {
    formId: string;
    formLabel: string;
    downloadLink: string;
    fileName: string;
    mailingListName: string | undefined;
  };
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const [country, setCountry] = useState<string>("");
  const [continent, setContinent] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [info, setInfo] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const searchParams = useSearchParams();

  const formDataRef = useRef({
    form: json.formLabel,
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    company: company,
    info: info,
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
  });

  useEffect(() => {
    fetch("https://ipwho.is/")
      .then((response) => response.json())
      .then((data) => {
        setCountry(data.country);
        setContinent(data.continent);
      });
  }, []);

  return (
    <div id={json.formId} className="dark rounded-lg bg-primary-dark-50 p-sm text-primary-dark-900 xl:p-6">
      {!submitted ? (
        <form
          target="_blank"
          method="GET"
          action={json.downloadLink}
          name={json.formId}
          id={json.formId}
          aria-label={json.formLabel}
          className="mt-xs grid scroll-mt-[240px] grid-cols-1 gap-sm text-sm sm:grid-cols-2 "
          onSubmit={async (e) => {
            e.preventDefault();
            analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
            analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });
            await fetch("/api/signup-to-list", {
              method: "POST",
              body: JSON.stringify({
                email: email,
                firstName: firstName,
                lastName: lastName,
                company: company,
                info: info,
                list: json?.mailingListName ?? "AI Ebook Signups",
              }),
            });
            window.open(json.downloadLink, "_blank");
            setSubmitted(true);
          }}
        >
          <div>
            <label htmlFor="first-name">
              First name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
            </label>
            <div className="mt-3xs">
              <input
                type="text"
                maxLength={256}
                name="Firstname"
                id="Firstname"
                value={firstName}
                onChange={(e) => {
                  formDataRef.current.first_name = e.target.value;
                  setFirstName(e.target.value);
                }}
                required
                autoComplete="given-name"
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name">
              Last name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
            </label>
            <div className="mt-3xs">
              <input
                type="text"
                name="Lastname"
                id="Lastname"
                value={lastName}
                onChange={(e) => {
                  formDataRef.current.last_name = e.target.value;
                  setLastName(e.target.value);
                }}
                required
                autoComplete="family-name"
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email">
              Work email <span className="font-light text-tertiary-red-500 opacity-60">*</span>
            </label>
            <div className="mt-3xs">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  formDataRef.current.work_email = e.target.value;
                  setEmail(e.target.value);
                }}
                required
                autoComplete="email"
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company">Company</label>
            <div className="mt-3xs">
              <input
                id="company"
                name="company"
                type="text"
                value={company}
                onChange={(e) => {
                  formDataRef.current.company = e.target.value;
                  setCompany(e.target.value);
                }}
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <button
              type="submit"
              className={cn(
                "group relative flex w-full items-center justify-center whitespace-nowrap px-sm py-xs font-medium leading-none",
                "rounded text-primary-neutral-50 transition hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
              )}
            >
              <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-primary-light-500 bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-primary-light-500/50 dark:to-primary-light-650/50"></span>
              <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 group-hover:opacity-0"></span>
              <span className="relative">Download now</span>
            </button>
            <input type="hidden" name="country" value={country} />
            <input type="hidden" name="continent" value={continent} />
            <input type="hidden" name="utm_source" value={formDataRef.current.utm_source ?? "unknown"} />
            <input type="hidden" name="utm_medium" value={formDataRef.current.utm_medium ?? "unknown"} />
            <input type="hidden" name="utm_campaign" value={formDataRef.current.utm_campaign ?? "unknown"} />
          </div>
          <div aria-hidden="true" id="required-description" className="mt-2xs text-xs font-light opacity-60">
            <span className="font-light text-tertiary-red-500 opacity-60">*</span> Required
          </div>
        </form>
      ) : (
        <div className="text-center">
          <span className="mx-auto mb-sm flex h-lg w-lg rounded-full bg-icon-gradient p-2xs text-primary-light-50">
            <Icon url="/assets/icons/check-glyph-24.svg" />
          </span>
          <div className="mb-sm font-display text-3xl font-bold">Thank you for registering.</div>
          <div className="text-lg">
            <a
              href={json.downloadLink}
              target="_blank"
              className="text-primary-light-500 hover:underline"
            >
              Click here to download your copy of the report.
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
