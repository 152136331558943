'use client';

import { useExperimentsStore } from "components/Analytics/NavigationEvents";
import PricingCalculatorSimplified from "./Simplified";
import PricingCalculatorOld from "./Old";
import { ComponentSingleInstanceFragment } from "contentful/gql/graphql";

export default function PricingCalculator(component: ComponentSingleInstanceFragment) {
  const { setViewedExperiments, viewedExperiments } = useExperimentsStore();

  const pageVariant = viewedExperiments["pricing_minimum_spend_messaging"];

  console.log("pageVariant", pageVariant);

  if (pageVariant === "control") {
    return <PricingCalculatorOld {...component} />;
  } else if (pageVariant === "simplified_1") {
    return <PricingCalculatorSimplified {...component} />;
  }
}