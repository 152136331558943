"use client";

import Icon from "components/Icon";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import { useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { cn } from "utils/functions";
import { analytics } from "lib/segment";
import Image from "next/image";
import GoogleCalendarLogo from "../../../public/assets/images/logos/google-calendar-logo.png";
import YahooLogo from "../../../public/assets/images/logos/yahoo-icon.svg";
import OutlookLogo from "../../../public/assets/images/logos/microsoft-outlook-logo.svg";
import Microsoft365Logo from "../../../public/assets/images/logos/microsoft-365-logo.svg";
import AppleLogo from "../../../public/assets/images/logos/apple-logo-black.svg";

export default function EventRegistrationForm({
  json,
}: {
  json: {
    formId: string;
    formLabel: string;
    mailingListName?: string;
    eventCalendarLinks: {
      linkGroupLabel: string;
      Google?: string;
      Yahoo?: string;
      Outlook?: string;
      "Outlook Web"?: string;
      Apple?: string;
      Microsoft365?: string;
    }[];
  };
}) {
  const [country, setCountry] = useState<string>("");
  const [countryRegion, setCountryRegion] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const searchParams = useSearchParams();

  const formDataRef = useRef({
    form: json.formLabel,
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    company: company,
    country: country,
    countryRegion: countryRegion,
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
  });

  useEffect(() => {
    fetch("/api/get-geo-and-ip")
      .then((response) => response.json())
      .then((data: any) => {
        setCountry(data.geo.country?.toUpperCase());
        setCountryRegion(data.geo.region?.toUpperCase());
      });
  }, []);

  return (
    <div id={json.formId} className="dark rounded-lg bg-primary-dark-50 p-sm text-primary-dark-900 xl:p-6">
      {!submitted ? (
        <form
          target="_blank"
          method="GET"
          name={json.formId}
          id={json.formId}
          aria-label={json.formLabel}
          className="mt-xs grid scroll-mt-[240px] grid-cols-1 gap-sm text-sm sm:grid-cols-2 "
          onSubmit={async (e) => {
            e.preventDefault();
            analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
            analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });
            if (json.mailingListName) {
              await fetch("/api/signup-to-list", {
                method: "POST",
                body: JSON.stringify({
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                  company: company,
                  list: json.mailingListName,
                }),
              });
            }
            setSubmitted(true);
          }}
        >
          <div>
            <label htmlFor="first-name">
              First name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
            </label>
            <div className="mt-3xs">
              <input
                type="text"
                maxLength={256}
                name="Firstname"
                id="Firstname"
                value={firstName}
                onChange={(e) => {
                  formDataRef.current.first_name = e.target.value;
                  setFirstName(e.target.value);
                }}
                required
                autoComplete="given-name"
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name">
              Last name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
            </label>
            <div className="mt-3xs">
              <input
                type="text"
                name="Lastname"
                id="Lastname"
                value={lastName}
                onChange={(e) => {
                  formDataRef.current.last_name = e.target.value;
                  setLastName(e.target.value);
                }}
                required
                autoComplete="family-name"
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email">
              Work email <span className="font-light text-tertiary-red-500 opacity-60">*</span>
            </label>
            <div className="mt-3xs">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  formDataRef.current.work_email = e.target.value;
                  setEmail(e.target.value);
                }}
                required
                autoComplete="email"
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company">Company</label>
            <div className="mt-3xs">
              <input
                id="company"
                name="company"
                type="text"
                value={company}
                onChange={(e) => {
                  formDataRef.current.company = e.target.value;
                  setCompany(e.target.value);
                }}
                className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <button
              type="submit"
              className={cn(
                "group relative flex w-full items-center justify-center whitespace-nowrap px-sm py-xs font-medium leading-none",
                "rounded text-primary-neutral-50 transition hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
              )}
            >
              <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-primary-light-500 bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-primary-light-500/50 dark:to-primary-light-650/50"></span>
              <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 group-hover:opacity-0"></span>
              <span className="relative">Reserve your spot</span>
            </button>
            <input type="hidden" name="country" value={country} />
            <input type="hidden" name="countryRegion" value={countryRegion} />
            <input type="hidden" name="utm_source" value={formDataRef.current.utm_source ?? "unknown"} />
            <input type="hidden" name="utm_medium" value={formDataRef.current.utm_medium ?? "unknown"} />
            <input type="hidden" name="utm_campaign" value={formDataRef.current.utm_campaign ?? "unknown"} />
          </div>
          <div aria-hidden="true" id="required-description" className="mt-2xs text-xs font-light opacity-60">
            <span className="font-light text-tertiary-red-500 opacity-60">*</span> Required
          </div>
        </form>
      ) : (
        <div className="text-center">
          <span className="mx-auto mb-sm flex h-lg w-lg rounded-full bg-icon-gradient p-2xs text-primary-light-50">
            <Icon url="/assets/icons/check-glyph-24.svg" />
          </span>
          <div className="mb-md font-display text-3xl font-bold">Thank you for registering.</div>
          <div className="text-lg">Add the event to your calendar to ensure you don&apos;t miss it.</div>
          {json.eventCalendarLinks.map((linkGroup, index) => {
            if (Object.keys(linkGroup).length < 2) return null;
            return (
              <div key={index} className="mt-sm">
                <div className="text-lg font-semibold">{linkGroup.linkGroupLabel}</div>
                <div className="mt-xs grid auto-cols-fr grid-flow-col-dense">
                  {linkGroup.Google && (
                    <a href={linkGroup.Google} target="_blank" rel="noreferrer" className="flex flex-col items-center">
                      <Image className="mb-3xs h-lg w-auto" alt="Google calendar logo" src={GoogleCalendarLogo} />
                      <div className="text-xs font-medium">Google</div>
                    </a>
                  )}
                  {linkGroup.Apple && (
                    <a href={linkGroup.Apple} target="_blank" rel="noreferrer" className="flex flex-col items-center">
                      <Image className="mb-3xs h-lg w-auto" alt="Apple logo" src={AppleLogo} />
                      <div className="text-xs font-medium">Apple</div>
                    </a>
                  )}
                  {linkGroup.Microsoft365 && (
                    <a
                      href={linkGroup.Microsoft365}
                      target="_blank"
                      rel="noreferrer"
                      className="flex flex-col items-center"
                    >
                      <Image className="mb-3xs h-lg w-auto" alt="Microsoft 365 logo" src={Microsoft365Logo} />
                      <div className="text-xs font-medium">Microsoft 365</div>
                    </a>
                  )}
                  {linkGroup.Outlook && (
                    <a href={linkGroup.Outlook} target="_blank" rel="noreferrer" className="flex flex-col items-center">
                      <Image className="mb-3xs h-lg w-auto" alt="Outlook logo" src={OutlookLogo} />
                      <div className="text-xs font-medium">Outlook</div>
                    </a>
                  )}
                  {linkGroup["Outlook Web"] && (
                    <a
                      href={linkGroup["Outlook Web"]}
                      target="_blank"
                      rel="noreferrer"
                      className="flex flex-col items-center"
                    >
                      <Image className="mb-3xs h-lg w-auto" alt="Outlook web logo" src={OutlookLogo} />
                      <div className="text-xs font-medium">Outlook Web</div>
                    </a>
                  )}
                  {linkGroup.Yahoo && (
                    <a href={linkGroup.Yahoo} target="_blank" rel="noreferrer" className="flex flex-col items-center">
                      <Image className="mb-3xs h-lg w-auto" alt="Yahoo logo" src={YahooLogo} />
                      <div className="text-xs font-medium">Yahoo</div>
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
