"use client";

import * as Slider from "@radix-ui/react-slider";
import clsx from "clsx";
import typography from "components/typography";
import { ComponentSingleInstanceFragment } from "contentful/gql/graphql";
import UserGlyph_32px from "icons/UserGlyph_32px";
import User_32px from "icons/UserOutline_32px";
import { FiClock, FiCreditCard } from "react-icons/fi";
import richTextParser, { RichText } from "utils/richTextParser";
import { usePricingCalculatorReducer } from "./pricingCalculatorReducer";
import { cn } from "utils/functions";

function logsliderValueToPosition(value: number) {
  var minp = 10;
  var maxp = 1000;

  var minv = Math.log(1);
  var maxv = Math.log(10000);

  // calculate adjustment factor
  var scale = (maxv - minv) / (maxp - minp);
  let position = Math.floor((Math.log(value) - minv) / scale + minp);

  return position;
}

function logsliderPositionToValue(position: number) {
  var minp = 10;
  var maxp = 1000;

  var minv = Math.log(1);
  var maxv = Math.log(10000);

  // calculate adjustment factor
  var scale = (maxv - minv) / (maxp - minp);
  let value = Math.floor(Math.exp(minv + scale * (position - minp)));

  return value;
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: logsliderValueToPosition(10),
    label: "10",
  },
  {
    value: logsliderValueToPosition(100),
    label: "100",
  },
  {
    value: logsliderValueToPosition(1000),
    label: "1K",
  },
  {
    value: logsliderValueToPosition(9000),
    label: "10K",
  },
];

const PricingCalculatorOld = ({ body }: ComponentSingleInstanceFragment) => {
  const { state, dispatch } = usePricingCalculatorReducer();

  const frequentUserCost = (state.frequentUsers || 0) * 20;

  const infrequentUserCost =
    0.4 * (state.averageUserHours || 0) < 20
      ? (state.infrequentUsers || 0) * 0.4 * (state.averageUserHours || 0)
      : (state.infrequentUsers || 0) * 20;

  const totalCost = frequentUserCost + infrequentUserCost;
  const displayTotalCost = totalCost < 40 ? 40 : totalCost;

  return (
    <div className="scroll-m-2xl space-y-md" id="pricing-calculator">
      <form className="w-full space-y-sm overflow-hidden rounded-xl border border-primary-dark-100 bg-primary-dark-50/40 p-sm pb-md lg:space-y-6 lg:p-md lg:pb-lg">
        <div className="flex flex-col justify-between sm:flex-row sm:items-end">
          <h3 className={typography.heading.secondaryComponentHeading}>Estimate your costs</h3>
          <div className="flex flex-col items-start md:flex-row md:items-center">
            <div
              className={cn(
                "mt-2 rounded border border-primary-light-300 bg-primary-light-50 px-3 text-2xs font-medium transition-opacity duration-300 ease-in-out md:mr-2 md:mt-0 md:text-xs",
                totalCost < 40 ? "opacity-100" : "opacity-0",
              )}
            >
              Less than 100 hours used per month
            </div>
            <span className="text-right text-sm">
              Total{" "}
              <span
                className="ml-3 text-right text-lg font-bold md:text-xl"
                style={{
                  fontVariantNumeric: "tabular-nums",
                }}
              >
                ${displayTotalCost.toLocaleString()}
              </span>
            </span>
          </div>
        </div>
        <div className="grid gap-10 md:grid-cols-7 lg:grid-cols-8">
          <div className="w-full md:col-span-6 lg:col-span-7">
            <label
              className="flex w-full flex-col justify-between md:flex-row md:items-center"
              htmlFor="infrequent-users"
            >
              <div className="flex items-center">
                <span className="mr-3xs h-md w-md rounded-full bg-primary-light-500/10 p-3xs text-primary-light-500">
                  <User_32px />
                </span>
                <span
                  className="mr-4 font-display text-xs font-medium text-primary-dark-800 md:text-base lg:whitespace-nowrap"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  How many users will use &lt; 50 hours per month?
                </span>
              </div>
              <div className="mt-4 flex justify-between text-sm md:mt-0">
                <div className="relative lg:w-full lg:whitespace-nowrap">
                  <span
                    className="text-[0.7rem]  font-semibold md:text-base"
                    style={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                  >
                    {state.infrequentUsers.toLocaleString()}{" "}
                  </span>
                  <b className="text-[0.6rem] md:text-sm"> x </b>{" "}
                  <span
                    className="text-[0.7rem] font-semibold md:text-base"
                    style={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                  >
                    30
                  </span>
                  <span className="text-[0.6rem] md:text-xs"> hours per month</span>
                  <b className="text-[0.6rem] md:text-sm"> x </b>
                  <span
                    className="text-[0.7rem] font-semibold md:text-base"
                    style={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                  >
                    $0.40
                  </span>
                  <span className="text-[0.6rem] md:text-xs"> per hour</span>
                  <div className="absolute -bottom-4 left-0 mr-2 mt-2 flex justify-end md:-bottom-5 md:-right-2">
                    <span
                      className="whitespace-nowrap text-right text-[0.7rem] opacity-50"
                      style={{
                        fontVariantNumeric: "tabular-nums",
                      }}
                    >
                      Assumes 30 hours of usage per month per user
                    </span>
                  </div>
                </div>
                <span
                  className="flex items-center  justify-end text-sm font-bold md:hidden md:text-xl lg:text-2xl"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  {totalCost < 40 ? "n/a" : `$${infrequentUserCost.toLocaleString()}`}
                </span>
              </div>
            </label>
            <div className="mt-2xs w-full">
              <Slider.Root
                className="relative flex h-sm touch-none select-none items-center"
                max={1000}
                min={0}
                step={1}
                defaultValue={[10]}
                value={[logsliderValueToPosition(state.infrequentUsers)]}
                onValueChange={(value) => {
                  dispatch({ type: "infrequentUsersChanged", payload: logsliderPositionToValue(value[0]) });
                }}
              >
                <Slider.Track className="relative h-2 flex-grow rounded-full bg-primary-dark-50">
                  <Slider.Range className="absolute h-full rounded-full bg-primary-light-500" />
                </Slider.Track>
                <Slider.Thumb className="focus:outline-none" asChild>
                  <div className="block h-sm w-sm overflow-hidden rounded-full bg-primary-neutral-50 p-[2px] shadow-md">
                    <div className="h-full w-full rounded-full bg-primary-light-500"></div>
                  </div>
                </Slider.Thumb>
                <div className="absolute left-0 top-[90%] flex h-full w-full justify-between font-display">
                  <div onClick={() => dispatch({ type: "infrequentUsersChanged", payload: 0 })}>0</div>
                  <div
                    onClick={() => dispatch({ type: "infrequentUsersChanged", payload: 10 })}
                    className="translate-x-xs"
                  >
                    10
                  </div>
                  <div
                    onClick={() => dispatch({ type: "infrequentUsersChanged", payload: 100 })}
                    className="translate-x-2xs"
                  >
                    100
                  </div>
                  <div
                    onClick={() => dispatch({ type: "infrequentUsersChanged", payload: 1000 })}
                    className="translate-x-2xs"
                  >
                    1K
                  </div>
                  <div
                    onClick={() => dispatch({ type: "infrequentUsersChanged", payload: 10000 })}
                    className="translate-x-0"
                  >
                    10K
                  </div>
                </div>
              </Slider.Root>
            </div>
          </div>
          <span
            className="hidden items-center justify-end pb-8  text-sm font-bold md:col-span-1 md:flex md:text-xl"
            style={{
              fontVariantNumeric: "tabular-nums",
            }}
          >
            {totalCost < 40 ? "n/a" : `$${infrequentUserCost.toLocaleString()}`}
          </span>
          <div className="md:col-span-6 lg:col-span-7">
            <label
              className="flex flex-col justify-between md:flex-row md:items-center lg:whitespace-nowrap"
              htmlFor="frequent-users"
            >
              <div className="flex items-center">
                <span className="mr-3xs h-md w-md rounded-full bg-primary-light-500/10 p-3xs text-primary-light-500">
                  <UserGlyph_32px />
                </span>{" "}
                <span
                  className="mr-4 font-display text-xs font-medium text-primary-dark-800 md:text-base lg:whitespace-nowrap"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  How many users will use &gt; 50 hours per month?
                </span>
              </div>
              <div className="mt-4 flex justify-between text-sm md:mt-0">
                <div>
                  <span
                    className="text-[0.7rem] font-semibold md:text-base"
                    style={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                  >
                    {state.frequentUsers.toLocaleString()}{" "}
                  </span>
                  <b className="text-[0.6rem] md:text-sm"> x </b>
                  <span
                    className="text-[0.7rem] font-semibold md:text-base"
                    style={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                  >
                    $20
                  </span>
                  <span className="text-[0.6rem] md:text-xs"> per month</span>
                </div>
                <span
                  className="flex items-center justify-end text-sm font-bold md:hidden md:text-xl lg:text-2xl"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  {totalCost < 40 ? "n/a" : `$${frequentUserCost.toLocaleString()}`}
                </span>
              </div>
            </label>
            <div className="mt-2xs w-full">
              <Slider.Root
                className="relative flex h-sm touch-none select-none items-center"
                max={1000}
                min={0}
                defaultValue={[10]}
                step={1}
                value={[logsliderValueToPosition(state.frequentUsers)]}
                onValueChange={(value) => {
                  dispatch({ type: "frequentUsersChanged", payload: logsliderPositionToValue(value[0]) });
                }}
              >
                <Slider.Track className="relative h-2 flex-grow rounded-full bg-primary-dark-50">
                  <Slider.Range className="absolute h-full rounded-full bg-primary-light-500" />
                </Slider.Track>
                <Slider.Thumb className="focus:outline-none" asChild>
                  <div className="block h-sm w-sm overflow-hidden rounded-full bg-primary-neutral-50 p-[2px] shadow-md">
                    <div className="h-full w-full rounded-full bg-primary-light-500"></div>
                  </div>
                </Slider.Thumb>
                <div className="absolute left-0 top-[90%] flex h-full w-full justify-between font-display">
                  <div onClick={() => dispatch({ type: "frequentUsersChanged", payload: 0 })}>0</div>
                  <div
                    onClick={() => dispatch({ type: "frequentUsersChanged", payload: 10 })}
                    className="translate-x-xs"
                  >
                    10
                  </div>
                  <div
                    onClick={() => dispatch({ type: "frequentUsersChanged", payload: 100 })}
                    className="translate-x-2xs"
                  >
                    100
                  </div>
                  <div
                    onClick={() => dispatch({ type: "frequentUsersChanged", payload: 1000 })}
                    className="translate-x-2xs"
                  >
                    1K
                  </div>
                  <div
                    onClick={() => dispatch({ type: "frequentUsersChanged", payload: 10000 })}
                    className="translate-x-0"
                  >
                    10K
                  </div>
                </div>
              </Slider.Root>
            </div>
          </div>
          <span
            className="hidden items-center justify-end pb-8 text-sm font-bold md:col-span-1 md:flex md:text-xl"
            style={{
              fontVariantNumeric: "tabular-nums",
            }}
          >
            {totalCost < 40 ? "n/a" : `$${frequentUserCost.toLocaleString()}`}
          </span>
        </div>
      </form>
      <div className="grid gap-md md:grid-cols-2">
        <div className="[&_h3]:text-2xl [&_p]:text-sm">{body && richTextParser(body as RichText)}</div>
        <table className="h-full w-full border-collapse gap-[1px] bg-primary-neutral-50 text-xs leading-none text-primary-dark-900 md:text-sm">
          <thead>
            <tr className="bg-primary-dark-50/50">
              <th className="border border-primary-dark-100">
                <div className={clsx("flex items-center justify-center p-sm text-center")}>
                  <FiClock className="mr-2xs h-sm w-sm" />
                  <span className="leading-none">Hours per user per month</span>
                </div>
              </th>
              <th className="border border-primary-dark-100">
                <div className={clsx("flex items-center justify-center p-sm text-center")}>
                  <FiCreditCard className="mr-2xs h-sm w-sm" />
                  <span className="leading-none">Billing per user per month</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-primary-dark-100">
                <div
                  className="p-sm text-center"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  less than 50
                </div>
              </td>
              <td className="border border-primary-dark-100">
                <div
                  className="p-sm text-center"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  $0.40 per hour per user starting with 100 hours per month
                </div>
              </td>
            </tr>
            <tr>
              <td className="border border-primary-dark-100">
                <div
                  className="p-sm text-center"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  More than 50
                </div>
              </td>
              <td className="border border-primary-dark-100">
                <div
                  className="p-sm text-center"
                  style={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                >
                  $20 per user
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingCalculatorOld;
