'use client';

import clsx from 'clsx';
import typography from 'components/typography';
import { analytics } from "lib/segment";
import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';
import { segmentTrackBooking } from 'utils/analytics';

const PartnerLeadForm = () => {
  const [country, setCountry] = useState<string>('');
  const [countryRegion, setCountryRegion] = useState<string>("");
  const [continent, setContinent] = useState<string>('');
  const [companyWebsite, setCompanyWebsite] = useState<string>("");
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>("");

  function isValidEmail(email: string) {
    return !(
      email.includes("@gmail.com") ||
      email.includes("@icloud.com") ||
      email.includes("@mailinator.com") ||
      email.includes("@business.com") ||
      email.includes("@edu.com") ||
      email.includes("@teleworm.com")
    );
  }

  const validEmail = isValidEmail(email);

  const formDataRef = useRef({
    form: "Partner Lead Form",
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    company_website: "",
    country: country,
    continent: continent,
  });

 useEffect(() => {
   fetch("/api/get-geo-and-ip")
     .then((response) => response.json())
     .then((data: any) => {
       setCountry(data.geo.country?.toUpperCase() ?? "");
       setCountryRegion(data.geo.region?.toUpperCase() ?? "");
     });
 }, []);

 function trackBookingActionsHandler(event: MessageEvent) {
   segmentTrackBooking(event.data["action"], formDataRef.current);
 }

 useEffect(() => {
   window.addEventListener("message", trackBookingActionsHandler, false);

   return () => {
     window.removeEventListener("message", trackBookingActionsHandler, false);
   };
 }, []);

 return (
   <div className="mx-auto max-w-md rounded-lg bg-primary-dark-50 p-sm xl:p-md">
     <Script id="script-chilipiper-src" src="https://js.chilipiper.com/marketing.js" />
     <Script id="script-chilipiper-action">
       {`function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments])}}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a},{});ChiliPiper.scheduling("appsmith", "inbound_router", {title: "Thanks! What time works best for a quick call?"})`}
     </Script>

     <form
       id="partner-lead-form"
       name="partner-lead-form"
       data-name="Partner Lead Form"
       action="#"
       method="get"
       aria-label="Partner Lead Form"
       className="grid grid-cols-1 gap-sm text-base text-primary-dark-800 sm:grid-cols-2"
       onSubmit={() => {
         analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
         analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });
       }}
     >
       <div className="md:col-span-2">
         <span className="mb-xs block text-sm font-semibold md:text-base">Interested in teaming up?</span>
         <p className={typography.heading.secondaryComponentHeading}>Contact our Partners team to get started.</p>
       </div>
       <div>
         <label htmlFor="first-name" className={typography.inputLabel}>
           First name
         </label>
         <div className="mt-[5px]">
           <input
             type="text"
             maxLength={256}
             name="Firstname"
             id="Firstname"
             value={firstName}
             onChange={(e) => {
               formDataRef.current.first_name = e.target.value;
               setFirstName(e.target.value);
             }}
             required
             autoComplete="given-name"
             className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
           />
         </div>
       </div>
       <div>
         <label htmlFor="last-name" className={typography.inputLabel}>
           Last name
         </label>
         <div className="mt-[5px]">
           <input
             type="text"
             name="Lastname"
             id="Lastname"
             value={lastName}
             onChange={(e) => {
               formDataRef.current.last_name = e.target.value;
               setLastName(e.target.value);
             }}
             required
             autoComplete="family-name"
             className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
           />
         </div>
       </div>
       <div className="sm:col-span-2">
         <label htmlFor="email" className={typography.inputLabel}>
           Work email
         </label>
         <div className="mt-[5px]">
           <input
             id="email"
             name="email"
             type="email"
             value={email}
             onChange={(e) => {
               formDataRef.current.work_email = e.target.value;
               setEmail(e.target.value);
             }}
             required
             autoComplete="email"
             className={clsx("block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]", {
               "border-tertiary-red-500": !validEmail,
             })}
           />
           {!validEmail && (
             <p className="mt-[5px] text-sm text-tertiary-red-500">Please enter a valid work email address.</p>
           )}
         </div>
       </div>
       <div className="sm:col-span-2">
         <label htmlFor="website" className={typography.inputLabel}>
           Company website
         </label>
         <div className="mt-[5px]">
           <input
             id="website"
             name="website"
             type="text"
             value={companyWebsite}
             onChange={(e) => {
               formDataRef.current.company_website = e.target.value;
               setCompanyWebsite(e.target.value);
             }}
             required
             className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
           />
         </div>
       </div>

       <div className="mt-sm sm:col-span-2">
         <button
           type="submit"
           disabled={!validEmail}
           className={clsx(
             "group flex w-full items-center justify-center whitespace-nowrap px-sm py-xs font-medium leading-none",
             "rounded bg-gradient-to-b from-primary-light-500 to-primary-light-550 text-primary-neutral-50 transition-colors hover:from-primary-light-500/20 hover:to-primary-light-550/20 hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
           )}
         >
           Book a meeting
         </button>
         <input type="hidden" name="lead_source" value="partner page" id="lead_source" />
         <input type="hidden" name="country" value={country} />
         <input type="hidden" name="countryRegion" value={countryRegion} />
         <input type="hidden" name="continent" value={continent} />
       </div>
     </form>
   </div>
 );
};

export default PartnerLeadForm;
