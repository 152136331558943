import React from 'react';
import { IconProps } from './IconProps';

function User_32px(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'User 32px';

  return (
    <svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={fill} stroke={fill}>
        <circle
          cx="16"
          cy="8"
          fill="none"
          r="6"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
        <path
          d="M28.475 30c-.264-6.67-5.74-12-12.475-12S3.79 23.33 3.525 30h24.95z"
          fill="none"
          stroke={fill}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth={strokewidth}
        />
      </g>
    </svg>
  );
}

export default User_32px;
