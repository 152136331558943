'use client';

import clsx from 'clsx';
import typography from 'components/typography';
import Script from 'next/script';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { segmentTrackBooking } from 'utils/analytics';
import { analytics } from "lib/segment";

const CustomerLeadForm: FC = () => {
  const [country, setCountry] = useState<string>('');
  const [continent, setContinent] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [countryRegion, setCountryRegion] = useState<string>("");

  const formDataRef = useRef({
    form: "Customer Lead Form - Find a partner",
    first_name: firstName,
    last_name: lastName,
    work_email: email,
  });

  useEffect(() => {
    fetch("/api/get-geo-and-ip")
      .then((response) => response.json())
      .then((data: any) => {
        setCountry(data.geo.country?.toUpperCase() ?? "");
        setCountryRegion(data.geo.region?.toUpperCase() ?? "");
      });
  }, []);

 function trackBookingActionsHandler(event: MessageEvent) {
   segmentTrackBooking(event.data["action"], formDataRef.current);
 }

 useEffect(() => {
   window.addEventListener("message", trackBookingActionsHandler, false);

   return () => {
     window.removeEventListener("message", trackBookingActionsHandler, false);
   };
 }, []);

 return (
   <div className="pb-6">
     <Script id="script-chilipiper-src" src="https://js.chilipiper.com/marketing.js" />
     <Script id="script-chilipiper-action">
       {`function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments])}}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a},{});ChiliPiper.scheduling("appsmith", "inbound_router", {title: "Thanks! What time works best for a quick call?"})`}
     </Script>

     <div className="mx-auto max-w-3xl">
       <h2 className={typography.heading.sectionHeading}>Need help finding the right partner?</h2>
       <form
         id="customer-lead-form"
         name="customer-lead-form"
         data-name="Customer Lead Form"
         action="#"
         method="get"
         aria-label="Customer Lead Form"
         className="mt-md grid grid-cols-1 gap-sm text-base text-primary-dark-800 sm:grid-cols-2"
         onSubmit={(e) => {
           e.preventDefault();
           analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
           analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });
         }}
       >
         <div>
           <label htmlFor="first-name" className={typography.inputLabel}>
             First name
           </label>
           <div className="mt-2xs">
             <input
               type="text"
               maxLength={256}
               name="Firstname"
               id="Firstname"
               value={firstName}
               onChange={(e) => {
                 formDataRef.current.first_name = e.target.value;
                 setFirstName(e.target.value);
               }}
               required
               autoComplete="given-name"
               className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
             />
           </div>
         </div>
         <div>
           <label htmlFor="last-name" className={typography.inputLabel}>
             Last name
           </label>
           <div className="mt-2xs">
             <input
               type="text"
               name="Lastname"
               id="Lastname"
               value={lastName}
               onChange={(e) => {
                 formDataRef.current.last_name = e.target.value;
                 setLastName(e.target.value);
               }}
               required
               autoComplete="family-name"
               className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="email" className={typography.inputLabel}>
             Work email
           </label>
           <div className="mt-2xs">
             <input
               id="email"
               name="email"
               type="email"
               value={email}
               onChange={(e) => {
                 formDataRef.current.work_email = e.target.value;
                 setEmail(e.target.value);
               }}
               required
               autoComplete="email"
               className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="company" className={typography.inputLabel}>
             {"What's your role?"}
           </label>
           <div className="mt-2xs">
             <select
               name="Role"
               id="Role-2"
               required
               autoComplete="Role"
               className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
             >
               <option value="">Select your role</option>
               <option value="Engineering">Engineering</option>
               <option value="Product">Product</option>
               <option value="Founder">Founder</option>
               <option value="Marketing">Marketing</option>
               <option value="Sales">Sales</option>
               <option value="Other">Other</option>
             </select>
           </div>
         </div>
         <fieldset className="sm:col-span-2">
           <legend className={typography.inputLabel}>Company size</legend>
           <div className="mt-4 grid grid-cols-1 gap-y-4">
             <div className="flex items-center">
               <input
                 id="size-25"
                 name="Company-Size"
                 defaultValue="1-25"
                 type="radio"
                 className="border-gray-300 h-4 w-4 "
               />
               <label htmlFor="size-25" className="ml-[5px]">
                 <span className="block ">1-25</span>
               </label>
             </div>
             <div className="flex items-center">
               <input
                 id="size-99"
                 name="Company-Size"
                 defaultValue="25-99"
                 type="radio"
                 className="border-gray-300 h-4 w-4 "
               />
               <label htmlFor="size-99" className="ml-[5px]">
                 <span className="block ">25-99</span>
               </label>
             </div>
             <div className="flex items-center">
               <input
                 id="size-499"
                 name="Company-Size"
                 defaultValue="100-499"
                 type="radio"
                 className="border-gray-300 h-4 w-4 "
               />
               <label htmlFor="size-499" className="ml-[5px]">
                 <span className="block ">100-499</span>
               </label>
             </div>
             <div className="flex items-center">
               <input
                 id="size-500"
                 name="Company-Size"
                 defaultValue="500+"
                 type="radio"
                 className="border-gray-300 h-4 w-4 "
               />
               <label htmlFor="size-500" className="ml-[5px]">
                 <span className="block ">500+</span>
               </label>
             </div>
           </div>
         </fieldset>
         <div className="sm:col-span-2">
           <div className="flex justify-between">
             <label htmlFor="Info" className={typography.inputLabel}>
               Tell us a little bit about your project.
             </label>
             <span id="Info-description" className=" hidden text-xs sm:inline-block sm:text-sm">
               Max. 500 characters
             </span>
           </div>
           <div className="mt-2xs">
             <textarea
               id="Info"
               name="Info"
               aria-describedby="Info"
               rows={4}
               className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
               defaultValue=""
             />
             <span id="Info-description" className=" inline-block text-xs sm:hidden sm:text-sm">
               Max. 500 characters
             </span>
           </div>
         </div>
         <div className="sm:col-span-2">
           <button
             type="submit"
             className={clsx(
               "group flex w-full items-center justify-center whitespace-nowrap px-sm py-xs font-medium leading-none",
               "rounded bg-gradient-to-b from-primary-light-500 to-primary-light-550 text-primary-neutral-50 transition-colors hover:from-primary-light-500/20 hover:to-primary-light-550/20 hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
             )}
           >
             Book a meeting
           </button>
           <input type="hidden" name="Questions" id="qa-partner" value="Partner related" />
           <input type="hidden" name="lead_source" value="find a partner" id="lead_source" />
           <input type="hidden" name="csquestions" id="csquestions" value="Partner related" />
           <input type="hidden" name="country" value={country} />
           <input type="hidden" name="countryRegion" value={countryRegion} />
           <input type="hidden" name="continent" value={continent} />
         </div>
       </form>
       <div className="success-message w-form-done hidden">
         <div>Thank you! Your submission has been received!</div>
       </div>
       <div className="w-form-fail hidden">
         <div>Oops! Something went wrong while submitting the form.</div>
       </div>
     </div>
   </div>
 );
};

export default CustomerLeadForm;
