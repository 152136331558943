import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { useExperimentsStore } from "components/Analytics/NavigationEvents";
import CtaLink from "components/CtaLink";
import Hyperlink from "components/Hyperlink";
import Icon from "components/Icon";
import TooltipPopover from "components/TooltipPopover";
import typography from "components/typography";
import { ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentPricingCardFragment } from "contentful/gql/graphql";
import { cn } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";

const container = cva(["rounded-xl px-6 py-10"], {
  variants: {
    edition: {
      free: "bg-secondary-light-100/60",
      business: "bg-secondary-light-100/60",
      enterprise: "bg-primary-neutral-50",
    },
  },
});

const PricingCard = ({
  planName,
  callToActionCollection,
  price,
  body,
  color,
  description,
}: ComponentPricingCardFragment) => {
  color = color?.split("-")[0];
  const edition = planName?.toLowerCase();

  const { viewedExperiments } = useExperimentsStore();
  const pricingPageVariant = viewedExperiments["pricing_minimum_spend_messaging"];

  return (
    <div
      className={container({ edition: edition as VariantProps<typeof container>["edition"] })}
      style={
        planName === "Enterprise"
          ? {
              boxShadow:
                "0px 0px 0px 2px #7441F7, 0px 0px 0px 5px #FFFFFF, 0px 0px 0px 6px #7441F730, 0px 0px 0px 10px #FFFFFF, 0px 0px 0px 11px #7441F710",
            }
          : undefined
      }
    >
      <div className={clsx("h-min")}>
        {planName && (
          <div className="mb-2xs flex flex-col">
            <h2 className={cn(typography.heading.secondaryComponentHeading, "font-medium")} id="tier-standard">
              {planName}
            </h2>
            {description && (
              <span
                className="mt-4xs text-sm text-primary-dark-900/70 lg:min-h-[48px]"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></span>
            )}
          </div>
        )}
        {planName === "Free" ? (
          <span
            className={clsx(
              "flex w-full flex-col justify-center text-3xl font-bold",
              price ? "lg:h-[67px]" : "lg:h-[5px]",
            )}
          >
            {price}
          </span>
        ) : planName === "Business" ? (
          <div className={clsx("flex flex-col justify-center", price ? "lg:h-[67px]" : "lg:h-[5px]")}>
            {price && (
              <>
                <div className="flex items-center">
                  <span className={clsx("text-3xl font-bold leading-none text-secondary-brand-500")}>{price}</span>
                  <div className={`ml-4xs flex h-full flex-col justify-end text-sm lowercase tracking-normal`}>
                    <span className="font-medium text-secondary-brand-500">/ month</span>
                  </div>
                </div>
                <div className="mt-3xs flex w-3/5 text-xs leading-tight">
                  {pricingPageVariant === "simplified_1" ? "For 100 hours of usage" : "Includes 100 hours of usage"}{" "}
                  <TooltipPopover content={"Additional usage at $0.4 per hour"} contentType="plainText" />
                </div>
              </>
            )}
          </div>
        ) : planName === "Enterprise" ? (
          <div className={clsx("flex flex-col justify-center", price ? "lg:h-[67px]" : "lg:h-[5px]")}>
            {price && (
              <>
                <div className="h-9 w-9 text-primary-light-500">
                  <Icon url="/assets/icons/comments-24-outline.svg" />
                </div>
                <div className="text-xs">Contact sales for pricing</div>
              </>
            )}
          </div>
        ) : null}
      </div>
      <div className={clsx("my-2xs flex items-center justify-center self-end")}>
        <div className="w-full">
          <div>
            <div
              className={clsx(
                "grid gap-sm",
                callToActionCollection?.items &&
                  Array.isArray(callToActionCollection?.items) &&
                  callToActionCollection?.items.length == 2
                  ? `lg:grid-cols-2`
                  : "",
              )}
            >
              {callToActionCollection?.items.map((item, index) => {
                const callToAction = getFragmentData(ctaFragment, item);
                return (
                  callToAction && (
                    <CtaLink
                      extendClassNames="max-w-none"
                      {...callToAction}
                      key={callToAction?.sys.id}
                      variant={planName === "Free" && index === 1 ? "free_secondary" : planName}
                      textAlign="center"
                    />
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {planName === "Business" && !(pricingPageVariant === "simplified_1") ? (
        <div className="my-2xs text-2xs font-light leading-tight">
          * Additional usage billed at $0.40 per hour, capped at $20 per user per month
        </div>
      ) : planName === "Free" ? (
        <div className="my-2xs h-4"></div>
      ) : null}
      <div className={clsx("opacity-70 [&_p]:!text-sm")} id="pricing-page-body">
        {body && richTextParser(body as RichText)}
      </div>
    </div>
  );
};

export default PricingCard;
