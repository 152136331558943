"use client";

import typography from "components/typography";
import { analytics } from "lib/segment";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { segmentTrackBooking } from "utils/analytics";
import { cn } from "utils/functions";

interface ContactFormProps {
  formHeading?: string;
  formCta?: string;
  formName?: string;
  formAccentColor?: string;
}

const ContactForm: FC<ContactFormProps> = ({ formHeading, formCta, formName, formAccentColor }) => {
  const [country, setCountry] = useState<string>("");
  const [countryRegion, setCountryRegion] = useState<string>("");
  const [questions, setQuestions] = useState<string[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [info, setInfo] = useState<string>("");

  function isValidEmail(email: string) {
    return !(
      email.includes("@gmail.com") ||
      email.includes("@icloud.com") ||
      email.includes("@mailinator.com") ||
      email.includes("@business.com") ||
      email.includes("@edu.com") ||
      email.includes("@teleworm.com")
    );
  }

  const validEmail = isValidEmail(email);

  const searchParams = useSearchParams();

  const formDataRef = useRef({
    form: `${formName ?? "Enterprise"} Contact Form`,
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    lead_source: searchParams.get("lead_source"),
    role: "",
    company_size: "",
    info: "",
    questions: "",
    country: country,
    countryRegion: countryRegion,
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
  });

  useEffect(() => {
    fetch("/api/get-geo-and-ip")
      .then((response) => response.json())
      .then((data: any) => {
        setCountry(data.geo.country?.toUpperCase());
        setCountryRegion(data.geo.region?.toUpperCase());
      });
  }, []);

  function trackBookingActionsHandler(event: MessageEvent) {
    segmentTrackBooking(event.data["action"], formDataRef.current);
  }

  useEffect(() => {
    window.addEventListener("message", trackBookingActionsHandler, false);

    return () => {
      window.removeEventListener("message", trackBookingActionsHandler, false);
    };
  }, []);

  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      setQuestions([...questions, target.value]);
    } else {
      setQuestions(questions.filter((qa) => qa !== target.value));
    }
  };

  return (
    <div className="rounded-lg bg-secondary-light-100/60 p-sm xl:p-md">
      <Script id="script-chilipiper-src" src="https://js.chilipiper.com/marketing.js" />
      <Script id="script-chilipiper-action">
        {`function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments])}}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a},{});ChiliPiper.scheduling("appsmith", "inbound_router", {title: "Thanks! What time works best for a quick call?"})`}
      </Script>

      <h2 className={typography.heading.innerSubheading}>
        {formHeading ?? "Book a demo with our team to get started with Appsmith Enterprise"}
      </h2>
      <form
        id="wf-form-Stay-updated-on-the-launch-Form"
        name="wf-form-Stay-updated-on-the-launch-Form"
        data-name="Stay updated on the launch Form"
        action="#"
        method="get"
        aria-label="Stay updated on the launch Form"
        className="mt-md grid grid-cols-1 gap-[20px] text-base sm:grid-cols-2"
        onSubmit={(e) => {
          formDataRef.current = {
            ...formDataRef.current,
            questions: questions.join(";"),
          };
          analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
          analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });
        }}
      >
        <div>
          <label htmlFor="first-name" className={typography.inputLabel}>
            First name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              type="text"
              maxLength={256}
              name="Firstname"
              id="Firstname"
              value={firstName}
              onChange={(e) => {
                formDataRef.current.first_name = e.target.value;
                setFirstName(e.target.value);
              }}
              required
              autoComplete="given-name"
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name" className={typography.inputLabel}>
            Last name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              type="text"
              name="Lastname"
              id="Lastname"
              value={lastName}
              onChange={(e) => {
                formDataRef.current.last_name = e.target.value;
                setLastName(e.target.value);
              }}
              required
              autoComplete="family-name"
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="email" className={typography.inputLabel}>
            Work email <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => {
                formDataRef.current.work_email = e.target.value;
                setEmail(e.target.value);
              }}
              onBlur={() => {
                if (validEmail) {
                  analytics?.identify(email, { email: email });
                }
              }}
              required
              autoComplete="email"
              className={cn(
                "block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]",
                !validEmail ? "border-tertiary-red-500" : "",
              )}
            />
            {!validEmail ? (
              <p className="mt-[5px] text-sm text-tertiary-red-500">Please enter a valid work email address.</p>
            ) : null}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="company" className={typography.inputLabel}>
            {"What's your role?"} <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <select
              name="Role"
              id="Role-2"
              required
              onChange={(e) => {
                formDataRef.current.role = e.target.value;
                setRole(e.target.value);
              }}
              value={role}
              autoComplete="Role"
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
            >
              <option value="">Select your role</option>
              <option value="Engineering">Engineering</option>
              <option value="Product">Product</option>
              <option value="Founder">Founder</option>
              <option value="Marketing">Marketing</option>
              <option value="Sales">Sales</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label htmlFor="Info" className={typography.inputLabel}>
              Tell us a little bit about your project.
            </label>
            <span id="Info-description" className=" hidden text-xs sm:inline-block sm:text-sm">
              Max. 500 characters
            </span>
          </div>
          <div className="mt-4xs">
            <textarea
              id="Info"
              name="Info"
              aria-describedby="Info"
              rows={4}
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              value={info}
              onChange={(e) => {
                formDataRef.current.info = e.target.value;
                setInfo(e.target.value);
              }}
            />
            <span id="Info-description" className=" inline-block text-xs sm:hidden sm:text-sm">
              Max. 500 characters
            </span>
          </div>
        </div>
        <div className="sm:col-span-2">
          <button
            type="submit"
            disabled={!validEmail}
            className={cn(
              "group flex w-full items-center justify-center whitespace-nowrap rounded px-sm py-xs font-medium leading-none",
              formAccentColor === "tangerine-400"
                ? "bg-gradient-to-b from-secondary-brand-500 to-secondary-brand-500/80 text-primary-neutral-50 hover:from-secondary-brand-500/30 hover:to-secondary-brand-500/20 hover:text-secondary-brand-500"
                : "bg-gradient-to-b from-primary-light-500 to-primary-light-550 text-primary-neutral-50 hover:from-primary-light-500/20 hover:to-primary-light-550/20 hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
              "transition-colors",
              !validEmail ? "cursor-not-allowed opacity-80" : "",
            )}
          >
            {formCta ?? "Get a demo"}
          </button>
          <input
            type="hidden"
            name="lead_source"
            id="lead_source"
            value={formDataRef.current.lead_source ?? "Website"}
          />
          <input type="hidden" name="csquestions" id="csquestions" value={questions.join(";")} />
          <input type="hidden" name="country" value={country} />
          <input type="hidden" name="countryRegion" value={countryRegion} />
        </div>
      </form>
      <div className="success-message w-form-done hidden">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="w-form-fail hidden">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
      <div aria-hidden="true" id="required-description" className="mt-sm text-sm font-light opacity-60">
        <span className="font-light text-tertiary-red-500 opacity-60">*</span> Required
      </div>
    </div>
  );
};

export default ContactForm;
